import React, { useState, useEffect } from "react";
import styles from "./FoodListItem.module.css";
import { FaAngleDoubleDown, FaAngleDown } from "react-icons/fa";
import CounterButton from "../elements/common/CounterButton";
import ConfirmItem from "../elements/common/ConfirmItem";
import RepeatPrevious from "./RepeatPrevious";
import CustomizeItemPage from "./CustomizeItemPage/index";
import { Button } from "../elements/Button";
import { useDispatch, useSelector } from "react-redux";
import { getFoodTypeColor } from "../../constants";
import { RxLineHeight } from "react-icons/rx";
import { FaArrowDown } from "react-icons/fa";
import { addItem } from "../../store/cartSlice";

const FoodListItem = ({ item, id, setShowConfirmOrder, showConfirmOrder }) => {
  const dispatch = useDispatch();
  const [showCustomize, setShowCustomize] = useState(
    localStorage.getItem("showCustomize") === "true"
  );
  const [itemsToShowFullName, setItemsToShowFullName] = useState([]);
  const [itemsToShowDescription, setItemsToShowDescription] = useState([]);

  const [showRepeatPopup, setShowRepeatPopup] = useState(false);
  const [showCustomizePage, setShowCustomizePage] = useState(false);
  // const dispatch = useDispatch();
  const itemsInCart = useSelector((state) => state.cart.items);
  const itemInCart = itemsInCart.find(
    (cartItem) => cartItem?._id === item?._id
  );
  const showCounterButton = itemInCart && itemInCart.quantity > 0;
  const showCustomizedButton = itemInCart && itemInCart.quantity > 0;

  const [sameItem, setSameItem] = useState(false);
  const [newItem, setNewItem] = useState(false);

  // Check if there are multiple items with the same ID in the cart
  const multipleItemsInCart =
    itemsInCart.filter((cartItem) => cartItem._id === item._id).length > 1;
  // Update localStorage whenever showCustomize or showConfirmOrder changes
  useEffect(() => {
    localStorage.setItem("showCustomize", showCustomize);
  }, [showCustomize]);

  useEffect(() => {
    localStorage.setItem("showConfirmOrder", showConfirmOrder);
  }, [showConfirmOrder]);

  const handleAddToCart = (item) => {
    setShowConfirmOrder(true);
    if (
      item?.quantityAddOn?.length == 1 &&
      item?.foodItemsAddOn_id?.length == 0
    ) {
      dispatch(
        addItem({
          ...item,
          quantity: 1,
          total: item?.basePrice,
          basePrice: item?.basePrice,
          quantityLevel: item?.quantityAddOn[0]?.label,
          instructions: item?.instructions || "",
          addOns: [],
          selectedBadges: [],
        })
      );
    } else {
      setNewItem(true);
      setShowCustomizePage(true);
      setShowConfirmOrder(true);
    }
  };
  const handleShowFullName = (item) => {
    if (itemsToShowFullName.includes(item)) {
      setItemsToShowFullName(itemsToShowFullName.filter((i) => i !== item));
    } else {
      setItemsToShowFullName([...itemsToShowFullName, item]);
    }
  };
  const handleShowDescription = (item) => {
    if (itemsToShowDescription.includes(item)) {
      setItemsToShowDescription(
        itemsToShowDescription.filter((i) => i !== item)
      );
    } else {
      setItemsToShowDescription([...itemsToShowDescription, item]);
    }
  };
  return (
    // <LazyMotion features={domAnimation}>
    <>
      <div
        className={`${
          styles.foodItem
        }  d-flex flex-row justify-content-between ${
          item?.available == false ? "text-muted" : ""
        }`}
      >
        <div className="mt-1 me-2" style={{ width: "2%" }}>
          <div
            className={styles.foodTag}
            style={{
              border: `1px solid ${getFoodTypeColor(item?.foodType)}`,
            }}
          >
            <div
              className={styles.disc}
              style={{
                backgroundColor: getFoodTypeColor(item?.foodType),
              }}
            ></div>
          </div>
        </div>
        <div className="ms-1" style={{ width: "97%" }}>
          <div
            className={styles.name}
            style={{
              width: "76%",
              wordBreak: "break-word",
              // textAlign: "justify",
              display: "flex",
              justifyContent: "space-between",
              lineHeight: "17px",
              // alignItems: "center",
              // whiteSpace: "nowrap",
              // overflow: "hidden",
              // textOverflow: "ellipsis",
            }}
            onClick={() => handleShowFullName(item)}
          >
            {item.name}

            <div className="position-absolute end-0 me-3 mt-2">
              <div className="ms-2">
                {showCounterButton ? (
                  <CounterButton
                    item={item}
                    setshowCustomize={setShowCustomize}
                    setShowConfirmOrder={setShowConfirmOrder}
                    setShowRepeatPopup={setShowRepeatPopup}
                    setShowCustomizePage={setShowCustomizePage}
                    setNewItem={setNewItem}
                    setSameItem={setSameItem}
                  />
                ) : (
                  <div>
                    {item?.available == false ? (
                      <div className="text-danger">Out of stock</div>
                    ) : (
                      <div
                        onClick={() => {
                          handleAddToCart(item);
                        }}
                      >
                        <Button
                          value="ADD +"
                          style={{
                            border: "none",
                            color: "white",
                            width: "65px",
                            borderRadius: "5px",
                            RxLineHeight: "19px",
                            padding: "3px",
                            fontSize: "13px",
                            height: "25px",
                          }}
                        ></Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.pricetag}>₹{item.basePrice}</div>
            </div>
          </div>

          <div className={styles.iteminfo}>
            {item?.available == false ? null : (
              <div
                style={{
                  display: "flex",
                  width: "48%",
                  justifyContent: "space-between",
                }}
              >
                {multipleItemsInCart ? null : (
                  <div
                    className={styles.customize}
                    key={item._id}
                    onClick={() => {
                      setShowCustomizePage(true);
                      setNewItem(false);
                      setSameItem(true);
                    }}
                  >
                    Customize
                  </div>
                )}
              </div>
            )}

            {showRepeatPopup && (
              <RepeatPrevious
                setShowCustomizePage={setShowCustomizePage}
                setShowRepeatPopup={setShowRepeatPopup}
                item={item}
                setSameItem={setSameItem}
                setNewItem={setNewItem}
              />
            )}
            {showCustomizePage && (
              <CustomizeItemPage
                item={itemInCart ? itemInCart : item}
                showCustomizePage={showCustomizePage}
                setShowCustomizePage={setShowCustomizePage}
                newItem={newItem}
                sameItem={sameItem}
                setShowCustomize={setShowCustomize}
                setShowConfirmOrder={setShowConfirmOrder}
              />
            )}
          </div>
          <div className="d-flex justify-content-between mt-1">
            <div className="position-relative" style={{ width: "80%" }}>
              <div
                className="custom-description"
                style={
                  itemsToShowDescription.includes(item)
                    ? {
                        color: "#a3a3a3",
                        width: "95%",
                        fontSize: "14px",
                        wordBreak: "break-word",
                      }
                    : item?.description.length > 180
                    ? {
                        color: "#a3a3a3",
                        width: "95%",
                        fontSize: "14px",
                        maxHeight: "84px",
                        whiteSpace: "wrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }
                    : {
                        color: "#a3a3a3",
                        width: "95%",
                        fontSize: "14px",
                        wordBreak: "break-word",
                        maxHeight: "84px",
                        overflow: "hidden",
                      }
                }
                // onClick={() => {
                //   handleShowDescription(item);
                // }}
              >
                {item?.description}
                {item?.description?.length > 180 &&
                itemsToShowDescription.includes(item) ? (
                  <span
                    className="ps-1"
                    style={{
                      display: "inline",
                      backgroundColor: "white",
                      fontSize: "14px",
                      color: "rgb(28, 147, 206)",
                    }}
                    onClick={() => {
                      handleShowDescription(item);
                    }}
                  >
                    show less
                  </span>
                ) : null}
              </div>
              {item?.description?.length > 180 &&
              !itemsToShowDescription.includes(item) ? (
                <span
                  className="ps-1"
                  style={{
                    position: !itemsToShowDescription.includes(item)
                      ? "absolute"
                      : "relative",
                    bottom: "0",
                    right: "10px",
                    backgroundColor: "white",
                    fontSize: "14px",
                    color: "rgb(28, 147, 206)",
                  }}
                  onClick={() => {
                    handleShowDescription(item);
                  }}
                >
                  {itemsToShowDescription.includes(item)
                    ? "show less"
                    : "show more"}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>{" "}
    </>
    // </LazyMotion>
  );
};

export default FoodListItem;
