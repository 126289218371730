import React, { useState, useEffect } from "react";
import FoodList from "../components/FoodItemList/FoodList";
import Layout from "../components/Layout/index";
import FoodCategoryList from "../components/Header/FoodCategoryList";
import Shimmer from "../components/elements/common/Shimmer";
import { useSelector, useDispatch } from "react-redux";
import { fetchFoodItems } from "../store/foodSlice";
import { getRestaurantById } from "../services/restaurant";
import { useNavigate } from "react-router";
import { createNotificationUser } from "../services/user";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { foodItems, status } = useSelector((state) => state.foods);
  const foodData = foodItems?.data;
  const { foodCategorySlice } = useSelector((state) => state.foodCategory);
  const [type, setType] = useState("");
  const showSearchBar = useSelector((state) => state.search.showSearchBar);
  const restaurantId = localStorage.getItem("restaurantId");
  const tableNumber = localStorage.getItem("tableNumber");
  // const filterCategory = (foodCategorySlice, rid) => {
  //   return foodCategorySlice?.filter(
  //     (category) => category.restaurant_id === rid
  //   );
  // };
  //food category
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuHeader, setshowMenuHeader] = useState(false);
  const firstCat =
    foodCategorySlice?.data && foodCategorySlice?.data?.length > 0
      ? foodCategorySlice?.data[0]
      : null;
  const [selectedCategory, setSelectedCategory] = useState(firstCat);
  const [isMenuFocused, setIsMenuFocused] = useState(false);
  useEffect(() => {
    async function fetchData() {
      const response = await getRestaurantById(restaurantId);
      // console.log(response);
      if (!response) {
        navigate("/qr", { replace: true });
      } else if (restaurantId && tableNumber) {
        localStorage.setItem("restaurantId", restaurantId);
        localStorage.setItem("tableNumber", tableNumber);
      }
    }
    fetchData();
  }, []);
  // const filteredCat = filterCategory(foodCategorySlice, res_id);
  const filteredCat = foodCategorySlice?.data;
  useEffect(() => {
    if (filteredCat && filteredCat?.length > 0) {
      setType(filteredCat[0]?.catID || filteredCat[0]?._id);
    }
  }, [foodCategorySlice]);

  useEffect(() => {
    dispatch(fetchFoodItems());
  }, []);

  const filteredFoodData =
    foodData &&
    foodData?.filter(
      (item) =>
        // (item?.category_id === type || item?.category?.id === type) &&
        restaurantId === item?.restaurant_id
    );

  return (
    <>
      <Layout
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        showMenuHeader={showMenuHeader}
        isMenuFocused={isMenuFocused}
        setIsMenuFocused={setIsMenuFocused}
      >
        {/* {!showSearchBar && <FoodCategoryList type={type} setType={setType} />} */}
        {status === "loading" || !foodData ? (
          <Shimmer />
        ) : !showSearchBar && filteredFoodData?.length > 0 ? (
          <FoodList
            foodData={filteredFoodData}
            foodCategorySlice={foodCategorySlice?.data}
            setSelectedCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            setShowMenu={setShowMenu}
            showMenuHeader={showMenuHeader}
            setshowMenuHeader={setshowMenuHeader}
            setIsMenuFocused={setIsMenuFocused}
          />
        ) : null}
      </Layout>
    </>
  );
};

export default Home;
