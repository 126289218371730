export const quantityPrice = Object.freeze({
  Full: "Full",
  Half: "Half",
  Medium: "Medium",
});

export const spicyLevel = Object.freeze({
  Less: "Less",
  Medium: "Medium",
  High: "High",
});
export const orderType = Object.freeze({
  Delivery: "delivery",
  PickUp: "pick-up",
  DineIn: "dine-in",
  pickUp: "pick-up",
  dineIn: "dine-in",
});
export const showOrderType = Object.freeze({
  "dine-in": "Dine In",
  delivery: "Delivery",
  "pick-up": "Pick Up",
});
export const generateOrderNumber = () => {
  return `ORD${Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000}`;
};

export const orderStatus = Object.freeze({
  InProcess: "InProcess",
  received: "Received",
  Received: "received",
  ready: "Ready",
  delivered: "Delivered",
  cancelled: "Cancelled",
});
export const getTextByStatus = Object.freeze({
  InProcess: "Your meal is getting prepared.",
  received: "We have received your order.",
  // Received: "received",
  ready: "The meal is ready for you.",
  delivered: "your order has been delivered.",
  cancelled: "your order has been cancelled.",
});
export const getFoodTypeColor = (type) => {
  switch (type) {
    case "Veg":
      return "#15A73E";
    case "Non-Veg":
      return "#8F3417";
    case "Egg":
      return "#d19602";
    default:
      return "#15A73E";
  }
};

export const getStatusColor = (status) => {
  switch (status) {
    case "InProcess":
      return "#ff6347";
    case "Received":
      return "#fcaf17";
    case "received":
      return "#fcaf17";
    case "Ready":
      return "#FA4A0C";
    case "ready":
      return "#FA4A0C";
    case "Cancelled":
      return "#880814";
    case "cancelled":
      return "#880814";
    case "completed":
      return "#069311";
    case "Completed":
      return "#069311";
    default:
      return "#FFB72B";
  }
};

export function getLabelByPrice(list, price) {
  for (const object of list) {
    for (const key in object) {
      // console.log(object[key]);
      if (object.hasOwnProperty(key) && object[key] === price) {
        return object.label;
      }
    }
    // return null; // Return null if price is not found
  }
}

export const getUserNameInitials = (name) => {
  const initials = ` https://api.dicebear.com/9.x/initials/svg?seed=${name}`;
  return initials;
};
