import React, { createContext, useState, useEffect } from "react";
import io from "socket.io-client";

const socketUrl = `${process.env.REACT_APP_BASE_URL}`; // Replace with your server URL
const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const newSocket = io(socketUrl, {
      transports: ["websocket"],
    });
    setSocket(newSocket);

    return () => newSocket.disconnect();
  }, [socketUrl]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

export default SocketContext;
