import React, { useState, useEffect, useRef } from "react";
import styles from "./Header.module.css";
import { RiSearchLine } from "react-icons/ri";
import logo from "../../assets/spicy-chili-logo.png";
import SearchBar from "../elements/common/Search/SearchBar";
import { setShowSearchBar } from "../../store/searchSlice";
import FilteredFoodList from "../elements/common/Search/FilteredFoodList";
import FoodList from "../FoodItemList/FoodList";
import { useSelector, useDispatch } from "react-redux";
import useToast from "../../utils/toast/useToast";
import { userIsLogged } from "../../services/user";
import { HiOutlineUser } from "react-icons/hi2";
import { getRestaurantById, getRating } from "../../services/restaurant";
import { MdArrowBackIos } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import EditUser from "../elements/common/EditUser";
import { motion } from "framer-motion";
import ExpandedMenu from "./ExpandedMenu";
import { DebounceInput } from "react-debounce-input";
import { RxCross2, RxDropdownMenu } from "react-icons/rx";
import fuzzysort from "fuzzysort";

const Header = ({
  selectedCategory,
  setSelectedCategory,
  showMenu,
  setShowMenu = () => {},
  showMenuHeader,
  isMenuFocused,
  setIsMenuFocused = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState(null);
  const location = useLocation();
  const [userLogged, setUserLogged] = useState(
    false || localStorage.getItem("userDetails") ? true : false
  );
  const id = localStorage.getItem("restaurantId");
  const showSearchBar = useSelector((state) => state.search.showSearchBar);
  const [restaurantData, setRestaurantData] = useState(null);
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const [showSidebar, setShowSidebar] = useState(false);
  const [showExpandedMenu, setShowExpandedMenu] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const user_id = localStorage.getItem("guestUserId")
    ? localStorage.getItem("guestUserId")
    : localStorage.getItem("guest_id");
  const restaurantId = localStorage.getItem("restaurantId");
  const foodItems = useSelector((state) => state.foods.foodItems);
  const { foodCategorySlice } = useSelector((state) => state.foodCategory);
  const [showLogin, setShowLogin] = useState(null);
  const [guestUserId, setGuestId] = useState("");
  const toast = useToast();
  const [foodData, setFoodData] = useState(null);
  // Ref for the search container
  const searchRef = useRef(null);
  useEffect(() => {
    async function fetch() {
      const dataFromApi = await getRestaurantById(id, toast);
      setRestaurantData(dataFromApi?.data);
      localStorage.setItem("restaurantName", dataFromApi?.data?.restaurantName);
      const taxesData = {
        cgst: dataFromApi?.data?.cgst ? dataFromApi?.data?.cgst : 0,
        sgst: dataFromApi?.data?.sgst ? dataFromApi?.data?.sgst : 0,
      };
      localStorage.setItem("taxes", JSON.stringify(taxesData));
    }
    fetch();
  }, [id]);

  const clearField = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchText("");
    setShowMenu(false)
    setSearchedData(null);
  };

  useEffect(() => {
    async function isLogged() {
      const response = await userIsLogged();
      if (
        location.search &&
        location.search?.includes("logged") &&
        response?.status === 201
      ) {
        setUserLogged(true);
      }
    }
    isLogged();
  }, [location]);

  const handleClose = () => setShowSidebar(false);
  const handleShow = () => setShowSidebar(true);

  const search = (e) => {
    const text = e.target.value.trim(); // Trim whitespace from input
    setSearchText(text); // Set state with the trimmed text
  };

  const handleSearch = () => {
    dispatch(setShowSearchBar(true));
  };

  const handleBackClick = () => {
    navigate("/welcome");
  };
  const handleSelectedCategory = (subCat) => {
    const category = foodCategorySlice?.data?.find((item) =>
      item.subCategory?.some((subItem) => subItem._id == subCat._id)
    );

    if (category) {
      setSelectedCategory(category);
    }
  };

  useEffect(() => {
    const foodData =
      foodItems?.data &&
      foodItems?.data?.filter((item) => restaurantId === item?.restaurant_id);
    setFoodData(foodData);
    if (searchText.length === 0) {
      setSearchedData(null);
    } else {
      const filteredData = foodData?.filter((item) => {
        const searchTextLower = searchText?.toLowerCase().replace(/\s/g, ""); // Remove spaces from search text
        const itemText = item?.name.toLowerCase().replace(/\s/g, ""); // Remove spaces from item name
        const result = fuzzysort?.single(searchTextLower, itemText);
        return result != null; // Return true if a match is found
      });
      setSearchedData(filteredData);
    }
  }, [searchText, foodItems, restaurantId]);

  useEffect(() => {
    const user_GuestId = localStorage.getItem("guestUserId");
    setGuestId(user_GuestId);
  }, [guestUserId, showLogin]);

  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchedData(null);
        setSearchText("");
      }
    };

    // Add event listener for click outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Disable scrolling when search bar is open
    if (showSearchBar) {
      document.body.classList.add(styles.noScroll);
    } else {
      document.body.classList.remove(styles.noScroll);
    }
  }, [showSearchBar]);

  return (
    <>
      {showSearchBar ? (
        <motion.div
          initial={{ y: "-100%", opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: [0.17, 0.67, 0.83, 0.67], duration: 0.3 }}
        >
          <SearchBar />
        </motion.div>
      ) : (
        <>
          <div className={styles.restaurentInfo}>
            <div className={`${styles.controls} ${isFocused ? "pt-2" : ""}`}>
              <div
                className={userLogged || user_id ? styles.backArrow : ""}
                onClick={() => (userLogged || user_id) && handleBackClick()}
              >
                {userLogged || user_id ? "History" : ""}
                {/* <MdArrowBackIos
                  color={
                    userLogged || user_id
                      ? isFocused
                        ? "black"
                        : "white"
                      : "transparent"
                  }
                  size={23}
                  className="position-relative"
                  onClick={() => (userLogged || user_id) && handleBackClick()}
                /> */}
              </div>
              <EditUser
                handleClose={handleClose}
                showSidebar={showSidebar}
                setShowLogin={setShowLogin}
                showLogin={showLogin}
                setGuestId={setGuestId}
                guestUserId={guestUserId}
              />
              <div
                className="d-flex align-items-center justify-content-end"
                ref={searchRef}
              >
                <div className={styles.controlUser}>
                  {userLogged ? (
                    <HiOutlineUser
                      className={styles.controlicons}
                      width={25}
                      height={25}
                      style={{ color: "#FFFFFF", marginLeft: "45px" }}
                      onClick={() => {
                        handleShow();
                      }}
                    />
                  ) : (
                    <a
                      className={styles.loginBtnH}
                      onClick={() => setShowLogin("login")}
                    >
                      Login
                    </a>
                  )}
                </div>
                <div className={styles.searchContainer}>
                  <DebounceInput
                    className={`${styles.search} ${styles.expandright} ${
                      isFocused ? styles.focused : ""
                    }`}
                    type="text"
                    minLength={1}
                    value={searchText}
                    id="searchright"
                    name="q"
                    placeholder="Search food items..."
                    debounceTimeout={300}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    onChange={(e) => {
                      search(e);
                    }}
                    autoComplete="off"
                  />
                  {!isFocused ? (
                    <label
                      className={`${styles.button} ${styles.searchbutton}`}
                      htmlFor="searchright"
                    >
                      <span className={styles.mglass}>
                        <RiSearchLine
                          size={20}
                          className={`mt-0 ${styles.searchIcon}`}
                        />
                      </span>
                    </label>
                  ) : (
                    <span
                      className={`${styles.mglass} ${styles.button} ${styles.searchbutton}`}
                    >
                      <RxCross2
                        className={`mt-0 ${styles.searchIcon}`}
                        onClick={(e) => clearField(e)}
                      />
                    </span>
                  )}
                </div>
                {searchedData && (
                  <div
                    className={showMenuHeader ? styles.result1 : styles.result}
                  >
                    {searchText && (
                      <RxCross2
                        className={`${styles.crossIcon}`}
                        onClick={(e) => clearField(e)}
                      />
                    )}
                    {searchedData && searchedData?.length > 0 ? (
                      <>
                        <FilteredFoodList
                          filteredData={searchedData}
                          searchTerm={searchText}
                          setSearchText={setSearchText}
                          setSearchedData={setSearchedData}
                        />
                        <FoodList
                          foodData={searchedData}
                          foodCategorySlice={foodCategorySlice?.data}
                          search={true}
                        />
                      </>
                    ) : (
                      searchText && (
                        <p className={styles.noResult}>
                          No items found based on your search.
                        </p>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.controls}>
              <div className={styles.information} style={{ display: "block" }}>
                <div className={styles.container}>
                  {restaurantData && Object.keys(restaurantData)?.length > 0 ? (
                    <>
                      <div className={styles.logo}>
                        <img
                          src={
                            restaurantData &&
                            restaurantData.image &&
                            typeof restaurantData.image === "string"
                              ? restaurantData.image
                              : restaurantData &&
                                restaurantData.image &&
                                restaurantData.image.url
                              ? restaurantData.image.url
                              : logo
                          }
                          alt="Hotel Logo"
                        />
                      </div>
                      <div className="text-start" style={{ width: "70%" }}>
                        <div className={`${styles.name} `}>
                          {restaurantData?.restaurantName || "Loading..."}
                        </div>
                        <div className={styles.address}>
                          {restaurantData?.streetAddress},{" "}
                          {restaurantData?.district}, {restaurantData?.state}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={styles.shimmer}></div>
                  )}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <>
            <div
              className={styles.restaurentInfoSecond}
              style={{
                top: showSearchBar ? "0" : "-125px",
              }}
            >
              <div
                className={styles.controls}
                onClick={() => setShowExpandedMenu(!showExpandedMenu)}
              >
                <span className={styles.topresname}>Menu</span>
                <RiSearchLine
                  size={22}
                  fontWeight={600}
                  className={styles.controlicons}
                  style={{ color: "white" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSearch();
                  }}
                />
              </div>
            </div>
            {showSearchBar && showExpandedMenu ? (
              <ExpandedMenu
                showExpandedMenu={showExpandedMenu}
                setShowExpandedMenu={setShowExpandedMenu}
              />
            ) : null}{" "}
            {showMenuHeader && (
              <div
                className={`${styles.menu} d-flex justify-content-between`}
                onClick={() => setShowMenu(!showMenu)}
              >
                <div className="d-flex align-items-center">
                  <span> Menu</span>{" "}
                  <span>
                    <RxDropdownMenu size={25} className={styles.menudownIcon} />
                  </span>
                </div>{" "}
                <div>
                  <DebounceInput
                    className={`${styles.search} ${styles.search1} ${
                      styles.expandright
                    } ${isMenuFocused ? styles.focused1 : ""}`}
                    type="text"
                    minLength={1}
                    value={searchText}
                    id="searchMenu"
                    name="q"
                    placeholder="Search food items..."
                    debounceTimeout={300}
                    onFocus={() => {
                      setIsMenuFocused(true);
                    }}
                    onBlur={() => setIsMenuFocused(false)}
                    onChange={(e) => {
                      e.stopPropagation()
                      search(e);
                      setShowMenu(false);
                    }}
                    autoComplete="off"
                  />
                  {!isMenuFocused ? (
                    <label htmlFor="searchMenu">
                      <span className={styles.mglass}>
                        <RiSearchLine
                          size={20}
                          className={`mt-0 ${styles.searchIcon}`}
                        />
                      </span>
                    </label>
                  ) : (
                    <span className={`${styles.mglass}`}>
                      <RxCross2
                        className={`mt-0 ${styles.searchIcon}`}
                        onClick={(e) => clearField(e)}
                      />
                    </span>
                  )}
                </div>
              </div>
            )}
            {showMenu ? (
              <>
                <motion.div
                  className={styles.stickyWrapper}
                  overlayHelper
                  initial={{ height: "50px" }} // Initial height
                  animate={{ height: isFocused ? "85%" : "50px" }} // Height to animate to
                  transition={{ duration: 0.5 }} // Animation duration
                >
                  {foodCategorySlice?.data?.map((cat) => {
                    return (
                      <>
                        <div className={styles.CatName}>{cat?.name}</div>
                        {
                          <div className={styles.FoodList}>
                            {cat?.subCategory?.map((subCat) => {
                              // const isSelected =
                              //   selectedCategory?.subCategory?.some(
                              //     (selectedSubCat) =>
                              //       selectedSubCat._id === subCat._id
                              //   );
                              const hasItems = foodData?.some(
                                (item) => item?.category?._id === subCat?._id
                              );

                              return (
                                <>
                                  {hasItems ? (
                                    <a
                                      className={styles.FoodItem}
                                      // onClick={() => handleSelectedCategory(subCat)}
                                      // key={subCat._id}
                                      href={`#${subCat._id}`}
                                    >
                                      <span className={`${styles.subcatname}`}>
                                        {subCat?.name}
                                      </span>
                                    </a>
                                  ) : null}
                                </>
                              );
                            })}
                          </div>
                        }
                      </>
                    );
                  })}
                </motion.div>
              </>
            ) : null}
          </>
        </>
      )}
    </>
  );
};

export default Header;
