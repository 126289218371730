import React from "react";
import Header from "../../components/Header/Header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { userIsLogged } from "../../services/user";
import useToast from "../../utils/toast/useToast";
const Layout = (props) => {
  const toast = useToast();
  const navigate = useNavigate();
  async function isLogged() {
    const res = await userIsLogged();
    if (res?.status === 200 && res?.data?.message === "no token") {
      navigate("/home", { replace: true });
    }
  }
  useEffect(() => {
    // userIsLogged(navigate, toast);
    isLogged();
  }, []);

  return (
    <>
      <Header
        selectedCategory={props.selectedCategory}
        setSelectedCategory={props.setSelectedCategory}
        showMenu={props.showMenu}
        setShowMenu={props.setShowMenu}
        showMenuHeader={props.showMenuHeader}
        setIsMenuFocused={props.setIsMenuFocused}
        isMenuFocused={props.isMenuFocused}
      />
      <div
        style={{
          backgroundColor: "#E4E4E4",
          // minHeight: "87vh",
          overflowX: "hidden",
        }}
      >
        {props.children}
      </div>
    </>
  );
};

export default Layout;
