import styles from "../styles/SplashScreen.module.css";
import applogo from "../assets/new.jpg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getRestaurantById,
  getRestaurantQRById,
} from "../services/restaurant.js";
import useToast from "../utils/toast/useToast.js";
import Cookies from "js-cookie";
const SplashScreen = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  const restaurantId = params.get("restaurantId");
  const tableNumber = params.get("tableNumber");
  const tableId = params.get("tableId");
  const group = params.get("group");
  const orderType = params.get("orderType");
  // const [resDetails, setResDetails] = useState({});
  const orderDetails = { group: group, orderType: orderType };
  localStorage.setItem("orderDetails", JSON.stringify(orderDetails));

  useEffect(() => {
    const fetchRestaurantData = async () => {
      if (!restaurantId || !tableNumber || !tableId) {
        redirectToQR();
        return;
      }

      try {
        const restaurant = await getRestaurantById(restaurantId);
        if (!restaurant) {
          showErrorAndRedirect("No restaurant found");
          return;
        }

        const response = await getRestaurantQRById(tableId);
        handleResponse(response);
      } catch (error) {
        showErrorAndRedirect(error.message);
      }
    };

    const handleResponse = (response) => {
      if (response?.status === 200) {
        storeDataInLocalStorage();
        setTimeout(() => navigate("/home", { replace: true }), 2500);
        Cookies.remove("token");
      } else {
        showErrorAndRedirect("Link expired.");
      }
    };

    const showErrorAndRedirect = (message) => {
      toast.showErrorToast(message);
      redirectToQR();
    };

    const redirectToQR = () => {
      navigate("/qr", { replace: true });
    };

    const storeDataInLocalStorage = () => {
      localStorage.setItem("restaurantId", restaurantId);
      localStorage.setItem("tableNumber", tableNumber);
      localStorage.setItem("tableId", tableId);
    };

    fetchRestaurantData();
  }, [restaurantId, tableNumber, tableId]);
  return (
    <div className={styles.outer}>
      <img src={applogo} alt="" className={styles.inner} />
    </div>
  );
};

export default SplashScreen;
