import styles from "../styles/UpdateProfile.module.css";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { useEffect, useRef, useState } from "react";
import { MdModeEditOutline } from "react-icons/md";
import { useFormik } from "formik";
import { getUserData, updateUser } from "../services/user";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import useToast from "../utils/toast/useToast";
import { getUserNameInitials } from "../constants";
const UpdateProfile = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const location = useLocation();
  const userData = location.state;
  const [base64String, setBase64String] = useState(null);
  const imageRef = useRef();
  const toast = useToast();
  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .trim()
      .matches(
        /^[a-zA-Z\s]+$/,
        "* Username must contain only letters and spaces."
      )
      .min(2, "* Username Is Too Short, It Must Be At Least 2 Characters.")
      .max(50, "* Username Is Too Long, It Must Not Exceed 50 Characters.")
      .required("* Username Is Required."),
    email: Yup.string()
      .email("* Invalid Email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "* Invalid Email"
      )
      .nullable()
      .optional(),
    phoneNumber: Yup.string()
      .matches(/^[6789]\d*$/, "* Invalid Phone Number")
      .length(10, "* Phone Number should be exactly 10 digits")
      .required("* Phone Number is Required"),
  });
  async function update({ userName, email, image, phoneNumber }) {
    console.log("update");
    const response = await updateUser({
      userName: userName,
      email: email,
      image: image,
      phoneNumber: phoneNumber,
    });
    if (response.message === "user updated" && response.user) {
      localStorage.setItem("userDetails", JSON.stringify(response.user));
      toast.showSuccessToast("Profile Updated Successfully");
    }
  }
  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      phoneNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Handle form submission here
      update({
        phoneNumber: values.phoneNumber,
        userName: values.userName.trim(),
        email: values.email,
        image: base64String ? base64String : userData?.profileImage,
      });
      const data = await getUserData(toast);
      localStorage.setItem("userDetails", JSON.stringify(data));
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (!file) {
      // Handle case when no file is selected
      toast.showWarningToast("No file selected.");
      return;
    }

    const reader = new FileReader();

    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 1.5 * 1024 * 1024; // 1.5MB in bytes

    if (allowedTypes.includes(file.type) && file.size <= maxSize) {
      reader.onloadend = () => {
        const base64 = reader.result;
        setImage(file);
        setBase64String(base64);
      };

      reader.readAsDataURL(file);
    } else {
      // Handle invalid file type or size here
      if (!allowedTypes.includes(file.type)) {
        toast.showWarningToast("Type must be png or jpg.");
      }
      if (file.size > maxSize) {
        toast.showWarningToast("Size must be under 1.5MB.");
      }
    }
  };

  useEffect(() => {
    if (userData) {
      formik.setFieldValue("userName", userData.userName);
      formik.setFieldValue("email", userData.email);
      formik.setFieldValue("phoneNumber", userData.phoneNumber);
    }
  }, []);
  return (
    <div className={styles.screen}>
      <MdOutlineArrowBackIos
        className={styles.controls}
        color="black"
        size={25}
        onClick={() => {
          navigate(-1, { replace: true });
        }}
      />
      <div className={styles.main}>
        <div className={styles.profile}>
          <img
            src={
              base64String ||
              userData?.profileImage ||
              getUserNameInitials(userData?.userName)
            }
            alt=""
          />

          <label htmlFor="file-input">
            <MdModeEditOutline size={30} className={styles.edit} fill="white" />
          </label>
          <input
            id="file-input"
            ref={imageRef}
            onChange={handleImageChange}
            type="file"
            className={styles.fileInput}
            autoComplete="off"
          />
        </div>
        <div className={styles.form}>
          <input
            type="text"
            placeholder="Name"
            id="edit-user-name"
            name="userName"
            value={formik.values.userName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete="off"
          />
          {formik.touched.userName && formik.errors.userName ? (
            <div className={styles.error}>{formik.errors.userName}</div>
          ) : null}

          <input
            type="text"
            placeholder="Email"
            id="edit-user-email"
            name="email"
            accept="image/png, image/jpeg, image/jpg"
            value={formik.values.email}
            onChange={formik.handleChange}
            autoComplete="off"
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className={styles.error}>{formik.errors.email}</div>
          ) : null}
          <input
            type="text"
            placeholder="Number"
            id="edit-user-phoneNumber"
            name="phoneNumber"
            accept="image/png, image/jpeg, image/jpg"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            autoComplete="off"
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className={styles.error}>{formik.errors.phoneNumber}</div>
          ) : null}
        </div>
        <button
          className={styles.button}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Update
        </button>
      </div>
    </div>
  );
};

export default UpdateProfile;
